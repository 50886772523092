import {createTheme} from "@mui/material";

const themeOptions = {
    palette: {
        primary: {
            main: '#3e4044',
            // main: 'rgb(81, 117, 139)',
            // main: 'rgb(81,106,139)',
        },
        secondary: {
            main: '#eaeaea',
        },
        error: {
            main: '#CC0000',
        },
        warning: {
            main: '#ce9703',
        },
        info: {
            main: '#53565B',
        },
        success: {
            main: '#007072',
        },
        // text: {
        //     primary: 'rgba(40,44,47,0.87)',
        //     secondary: 'rgba(40,44,47,0.54)',
        //     disabled: 'rgba(40,44,47,0.38)',
        //     hint: 'rgba(40,44,47,0.38)',
        // },
        divider: 'rgba(67,67,67,0.12)',
    },
    typography: {
        fontFamily: 'Montserrat',
        allVariants: {
            color: 'rgba(40,44,47,0.87)',
        }
    },
    // props: {
    //     MuiButton: {
    //         // size: 'large',
    //         dense: true
    //     },
    //     MuiButtonGroup: {
    //         size: 'small',
    //     },
    //     MuiCheckbox: {
    //         size: 'small',
    //     },
    //     MuiFab: {
    //         size: 'small',
    //     },
    //     MuiFormControl: {
    //         margin: 'dense',
    //         size: 'small',
    //     },
    //     MuiFormHelperText: {
    //         margin: 'dense',
    //     },
    //     MuiIconButton: {
    //         size: 'small',
    //     },
    //     MuiInputBase: {
    //         margin: 'dense',
    //     },
    //     MuiInputLabel: {
    //         margin: 'dense',
    //     },
    //     MuiRadio: {
    //         size: 'small',
    //     },
    //     MuiSwitch: {
    //         size: 'small',
    //     },
    //     MuiTextField: {
    //         margin: 'dense',
    //         size: 'small',
    //     },
    // },
};

export const theme = createTheme(themeOptions)