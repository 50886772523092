import {Base3dViewerManager} from "../Template/component/Base3dViewerManager";
import {ZipArchive} from "@shortercode/webzip";
import {IApiDataSceneSpecification} from "./IApiDataSceneSpecification";
import {IApiDataPolylineEntity} from "./IApiDataPolylineEntity";
import {PolylineEntity} from "../Template/entities/PolylineEntity";
import {GLTFLoader} from "three-stdlib";
import {IApiDataTopographyEntity} from "./IApiDataTopographyEntity";
import {TopographyEntity} from "../Template/entities/TopographyEntity";
import {Mesh, MeshStandardMaterial, SkinnedMesh, Vector3} from "three";
import {GltfDroneEntity} from "../Template/entities/GltfDroneEntity";

class DataService {

    async readSceneryByUrl(url: string, manager: Base3dViewerManager) {
        const specificationName = "scene.spec"

        const response = await fetch(url, {
            method: "GET"
        })

        const blob = await response.blob()
        const archive = await ZipArchive.from_blob(blob);

        // -- Specification --
        let entry = archive.get(specificationName)!
        const specification = JSON.parse(await entry.get_string()) as IApiDataSceneSpecification

        for (const entityInfo of specification.entities) {

            if (entityInfo.type == "Polyline") {
                entry = archive.get(entityInfo.entityPath)!
                const dataPolyline = JSON.parse(await entry.get_string()) as IApiDataPolylineEntity
                manager.addEntity(new PolylineEntity(dataPolyline, manager))
            }
            if (entityInfo.type == "Topography") {
                entry = archive.get(entityInfo.entityPath)!
                const dataTopography = JSON.parse(await entry.get_string()) as IApiDataTopographyEntity
                manager.addEntity(new TopographyEntity(dataTopography, manager))
            }
        }
    }


    async readDrone(manager: Base3dViewerManager) {
        const loader = new GLTFLoader()
        const gltf = await loader.loadAsync('/Drone.glb')

        gltf.scene.traverse(object => {
            if ((object as SkinnedMesh).isSkinnedMesh) {
                const skinnedMesh = object as SkinnedMesh
                skinnedMesh.material = new MeshStandardMaterial({
                    color: Math.random() > 0.5 ? 'rgb(81,110,139)' : 'rgb(204,204,204)'
                })
            }
        })
        const droneEntity = new GltfDroneEntity(gltf, {
            addToInteractiveManager: true,
            color: "",
            extension: "", hasTooltip: true, name: "",
            type: "test", tooltip: "AVIONI"
        }, manager)
        // X = 93199.1820     Y = 21963.8150     Z = 3065.0000
        // X = 91945.7660     Y = 21647.4080     Z = 2945.0000
        // X = 93021.9580     Y = 20846.4860     Z = 2975.0000

        // droneEntity.setPath([
        //     new Vector3(93199.1820    , 21963.8150, 3065.0000),
        //     new Vector3(91945.7660   , 21647.4080 ,  2945.0000),
        //     new Vector3(93021.9580, 20846.4860, 2975.0000)
        // ])

        droneEntity.setPath([
            new Vector3(91857.09,21904.50,3060.00),
            new Vector3(91734.42,21495.03,3135.00),
            new Vector3(91954.84,21287.24,3120.00),
            new Vector3(92113.58,21129.62,3165.00),
            new Vector3(92276.16,21079.74,3135.00),
            new Vector3(92453.79,21079.74,3135.00),
            new Vector3(92791.13,20807.79,3120.00),
            new Vector3(92938.24,20764.35,3090.00),
            new Vector3(93206.20,20637.51,3090.00),
            new Vector3(93478.86,20476.15,3105.00),
            new Vector3(93457.73,20812.40,3120.00),
            new Vector3(92799.08,21018.18,2985.00),
            new Vector3(92644.46,21026.38,3030.00),
            new Vector3(92107.40,21451.13,2985.00),
            new Vector3(91951.77,21501.82,3015.00),
            new Vector3(91931.91,21650.99,3015.00),
            new Vector3(91936.68,21770.96,3015.00),
            new Vector3(91938.13,21807.29,3015.00),
            new Vector3(91955.96,21846.24,3015.00),
            new Vector3(92006.92,21859.75,3015.00),
            new Vector3(92440.17,21413.76,2880.00),
            new Vector3(92525.56,21395.10,2850.00),
            new Vector3(92885.98,21185.55,2925.00),
            new Vector3(93170.18,21072.51,3015.00),
            new Vector3(93379.90,20895.26,3090.00),
            new Vector3(93329.26,21102.71,3120.00),
            new Vector3(93116.26,21249.42,3090.00),
            new Vector3(92691.40,21642.65,2895.00),
            new Vector3(92308.97,21716.41,2940.00),
            new Vector3(91980.23,21923.03,3030.00),
            new Vector3(91908.53,22095.74,3165.00),
            new Vector3(92652.23,22095.74,3165.00),
            new Vector3(93148.52,22264.83,3135.00),
            new Vector3(93559.71,22114.93,3165.00),
            new Vector3(93389.40,21286.36,3135.00),
            new Vector3(92721.66,21711.03,2925.00),
            new Vector3(92303.93,21830.06,3045.00),
            new Vector3(92254.98,22004.97,3135.00),
            new Vector3(93067.92,22132.22,3186.06),
            new Vector3(93156.39,21970.11,3120.00),
            new Vector3(92881.92,21922.99,3090.00),
            new Vector3(92670.37,21909.22,3090.00),
        ])

        droneEntity.setPathVisibility(false)
        // droneEntity.setPath([
        //     new Vector3(-4, 4, 2),
        //     new Vector3(-6, 0, 0),
        //     new Vector3(-4, -4, 3),
        //     new Vector3(0, 0, 0),
        //     new Vector3(4, -4, 0),
        //     new Vector3(6, 0, -2),
        //     new Vector3(4, 4, 0),
        //     new Vector3(0, 6, 0),
        // ])

        // manager.addEntity(droneEntity)


        return droneEntity

        // console.log(gltf)
    }
}

export const dataService = new DataService()