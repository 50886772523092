import {Button, FormControl, Grid, IconButton, Tooltip, Typography, TypographyProps} from "@mui/material";
import React, {FC, useEffect} from "react";
import {imageStyleSx, spacing} from "../../../Resources/styles";
import {useBase64Image} from "../../../hooks/useBase64Image";
import {ContentPaste, Download, UploadFile} from "@mui/icons-material";
import {apiUtils, NoneImage} from "../../../api/ApiConfig";
import * as muiImage from "mui-image";
import {Strings} from "../../../Resources/Strings";
import {useCustomSnackbar} from "../../../hooks/useCustomSnackbar";

export interface ImageUploadComponentProps {
    label: string,
    defaultImageUrl?: string
    labelProps?: TypographyProps,
    onChange?: (imageBase64: string) => void
}

export const ImageUploadComponent: FC<ImageUploadComponentProps> = (props) => {

    const {mutateAsync: loadImageAsync, data: image, isPending} = useBase64Image()
    const {enqueueError, enqueueWarning} = useCustomSnackbar()

    useEffect(() => {
        if (!image)
            loadImageAsync(props.defaultImageUrl)
    }, [props]);

    useEffect(() => {
        if (image)
            props.onChange?.(image)
    }, [image])

    const pasteCallback = async () => {
        try {
            const clipboardItems = await navigator.clipboard.read()
            // Check Clipboard Size
            if (clipboardItems.length < 1) {
                enqueueWarning(Strings.EmptyClipboard)
                return
            }

            // Check for images types
            const clipboardItem = clipboardItems[0]
            if (clipboardItem.types.findIndex(value => value == 'image/png') == -1) {
                enqueueWarning(Strings.ClipboardDoesNotHaveImage)
                return
            }

            const blobOutput = await clipboardItem.getType('image/png')
            const data = await apiUtils.getBase64(blobOutput)
            await loadImageAsync(data)
        } catch (e) {
            enqueueError(e)
        }
    }


    const imageUploadCallback = async (event: any) => {
        let htmlInputElement: HTMLInputElement = event.target
        await loadImageAsync(htmlInputElement)
    }


    return <Grid container spacing={spacing}>

        <Grid item xs={12}>
            <Grid container alignItems='center'>
                <Grid item xs='auto'>
                    <Typography {...props.labelProps}>{props.label}</Typography>
                </Grid>

                <Grid item xs>

                </Grid>

                <Grid item xs='auto'>
                    <Tooltip title={Strings.UploadImage}>
                        <IconButton component='label' color='primary'>
                            <UploadFile/>
                            <input
                                type="file"
                                accept="image/png,image/jpeg"
                                onChange={imageUploadCallback}
                                hidden
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                    <Tooltip title={Strings.PasteImage} onClick={pasteCallback}>
                        <IconButton color='primary'>
                            <ContentPaste/>
                        </IconButton>
                    </Tooltip>
                </Grid>

            </Grid>
        </Grid>

        {image && <Grid item xs={12}>
            <muiImage.Image alt={props.label}
                            src={image}
                            fit='contain'/>
        </Grid>}


        <Grid item xs={12}/>

    </Grid>
}