import {
    AnimationMixer, ArrowHelper, Box3, BoxHelper,
    BufferGeometry, ConeGeometry,
    Float32BufferAttribute, Group,
    LineBasicMaterial, LineLoop, Matrix4, Mesh,
    MeshNormalMaterial, MeshStandardMaterial, Object3D, SkinnedMesh,
    Vector3
} from "three";
import {GLTF} from "three-stdlib/loaders/GLTFLoader";
import {IApiDataEntity} from "../../api/IApiDataEntity";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {IClockArguments} from "../middleware/IClockArguments";
import {GltfEntity} from "./GltfEntity";
import {Vehicle} from "yuka";
import * as YUKA from 'yuka';
import {PolylineEntity} from "./PolylineEntity";
import {GLTFLoader} from "three-stdlib";


export class GltfDroneEntity extends GltfEntity {
    private mixer: AnimationMixer
    private currentDistance = 0

    private time: YUKA.Time
    private entityManager: YUKA.EntityManager
    private readonly vehicle: YUKA.Vehicle
    private position: Vector3[]
    private line: LineLoop


    constructor(gltf: GLTF, data: IApiDataEntity, manager: Base3dViewerManager) {
        super(gltf, data, manager);
        this.position = []


        const lineMaterial = new MeshStandardMaterial({
            color: 'rgb(127,127,127)'
        })
        const lineGeometry = new BufferGeometry().setFromPoints([])
        this.line = new LineLoop(lineGeometry, lineMaterial)

        manager.scene.add(this.line)

        this.time = new YUKA.Time();
        this.entityManager = new YUKA.EntityManager();

        // Vehicle
        this.vehicle = new Vehicle();
        this.object.matrixAutoUpdate = false

        this.vehicle.setRenderComponent(this.object, (entity, renderComponent) => {
            const matrix = entity.worldMatrix as any
            renderComponent.matrix.copy(matrix)
        })


        this.vehicle.scale.set(300, 300, 300)


        this.entityManager.add(this.vehicle);


        this.mixer = new AnimationMixer(gltf.scene);
        const action = this.mixer.clipAction(gltf.animations[1]);
        action.play();
    }


    setPath(positions: Vector3[]) {

        this.position = []
        this.vehicle.steering.clear()

        const path = new YUKA.Path();
        for (const position of positions) {
            path.add(new YUKA.Vector3(position.x, position.y, position.z))
            this.position.push(position)
        }

        this.vehicle.position.set(positions[0].x,positions[0].y,positions[0].z)

        this.line.geometry.setFromPoints(positions)

        const followPathBehavior = new YUKA.FollowPathBehavior(path, 80);

        this.vehicle.steering.add(followPathBehavior);
        this.vehicle.maxSpeed = 80
        this.vehicle.maxTurnRate = Math.PI


        const onPathBehavior = new YUKA.OnPathBehavior(path);
        onPathBehavior.radius = 50;
        this.vehicle.steering.add(onPathBehavior);
    }

    setPathVisibility(visibility: boolean) {
        this.line.visible = visibility
    }


    override onAnimationFrame() {
        super.onAnimationFrame();
        const delta = this.time.update().getDelta();
        this.entityManager.update(delta)
    }

    override onMouseOver() {
        super.onMouseOver();
    }

    override onClock(clockArguments: IClockArguments) {
        super.onClock(clockArguments);
        this.mixer.update(clockArguments.deltaTime)
    }
}