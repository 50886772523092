import {
    BufferAttribute,
    BufferGeometry,
    Color,
    DoubleSide,
    Intersection,
    Mesh,
    MeshStandardMaterial,
    Object3D
} from "three";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {BaseEntity} from "./BaseEntity";
import {IApiDataTopographyEntity} from "../../api/IApiDataTopographyEntity";


export class TopographyEntity extends BaseEntity<Mesh> {

    constructor(data: IApiDataTopographyEntity, manager: Base3dViewerManager) {
        const mesh = new Mesh()
        const geometry = new BufferGeometry();
        geometry.setAttribute("position", new BufferAttribute(new Float32Array(data.triangleVertices), 3))
        geometry.computeVertexNormals()
        geometry.computeBoundingBox()
        const material = new MeshStandardMaterial({
            side: DoubleSide,
            color: new Color("rgb(42,95,208)")
        })
        mesh.geometry = geometry
        mesh.material = material
        super(mesh, data, manager)
    }

    onMouseOver() {
        const tooltipManager = this.manager.getTooltipManager()
        tooltipManager.setVisibility(true)
    }

    onMouseMove(ray: Intersection<Object3D>) {
        const tooltipManager = this.manager.getTooltipManager()
        tooltipManager.setHtmlContent(`<strong>${this.name}</strong>`)
    }

    onMouseOut() {
        const tooltipManager = this.manager.getTooltipManager()
        tooltipManager.setVisibility(false)
    }
}



