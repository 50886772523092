import {Clock, Intersection, Object3D} from "three";
import {Base3dViewerManager} from "../component/Base3dViewerManager";
import {IApiDataEntity} from "../../api/IApiDataEntity";
import {IClockArguments} from "../middleware/IClockArguments";

export abstract class BaseEntity<T extends Object3D> {
    object: T
    protected manager: Base3dViewerManager
    addToInteractiveManager: boolean
    name: string
    hasTooltip: boolean
    tooltip?: string

    constructor(object: T, data: IApiDataEntity, manager: Base3dViewerManager) {
        this.object = object
        this.manager = manager
        this.addToInteractiveManager = data.addToInteractiveManager
        this.hasTooltip = data.hasTooltip
        this.tooltip = data.tooltip
        this.name = data.name
    }

    public onMouseOver() {
        this.manager.getTooltipManager().setVisibility(true)
    }

    public onMouseMove(ray: Intersection<Object3D>) {
        this.manager.getTooltipManager().setHtmlContent(this.name)
    }

    public onMouseOut() {
        this.manager.getTooltipManager().setVisibility(false)
        this.manager.getTooltipManager().setHtmlContent("")
    }

    public onMouseClick(ray: Intersection<Object3D>) {

    }

    public onClock(clockArguments:IClockArguments){

    }

    public onAnimationFrame(){

    }
}