import * as React from "react";
import {environment} from "./api/ApiConfig";
import {BhpGeomeasurementsRoutes} from "./environment/BhpGeomeasurementsRoutes";
import {EnvironmentNames} from "./environment/EnvironmentNames";
import {useRoles} from "./hooks/useRoles";

export const RoutesComponent = () => {


    if (environment.includes(EnvironmentNames.Geomeasurement)) {
        return <BhpGeomeasurementsRoutes/>
    }

    return <></>

    //
    // return <Box margin={margin} sx={{pointerEvents: isInProgress ? 'none' : 'auto', overflow: 'hidden'}}>
    //
    //
    //     <Routes>
    //
    //
    //         <Route path="/" element={<TestViewerComponent/>}/>
    //
    //         {/*Plant Components*/}
    //         <Route path="/plant/selectCubicle/:plantId" element={<StoragePlantInCubicleComponent/>}/>
    //         <Route path="/plant/shelf/:plantId" element={<PlantStorageViewerComponent/>}/>
    //         <Route path="/plant/Edit/:id" element={<PlantEditComponent/>}/>
    //         <Route path="/plant/:id" element={<PlantDetailsComponent/>}/>
    //
    //         <Route path="/plant/sheet" element={<PlantSheetCollectionComponent/>}></Route>
    //         <Route path="/plant/all" element={<PlantCollectionComponent/>}></Route>
    //
    //         <Route path="/plantImage/create/:plantId" element={<PlantCreateImageComponent/>}/>
    //
    //         <Route path="/userdetails" element={<UserDetailsComponent/>}/>
    //
    //         <Route path="/epipremnum/create" element={<EpipremnumCreateComponent/>}/>
    //         <Route path="/epipremnum/scan" element={<EpipremnumScanComponent/>}/>
    //         <Route path="/epipremnum/:id" element={<EpipremnumDetailsRoute/>}/>
    //         <Route path="/epipremnum/all" element={<EpipremnumAllComponent/>}/>
    //         <Route path="/epipremnum/edit/:id" element={<EpipremnumEditComponent/>}/>
    //
    //         <Route path="/epipremnumRawPicture/create/:epipremnumId"
    //                element={<EpipremnumRawPictureCreateComponent/>}/>
    //         <Route path="/epipremnumRawPicture/:id"
    //                element={<EpipremnumRawPictureDetailsComponent/>}/>
    //         <Route path="/epipremnumRawPicture/create_commercial_picture/:id"
    //                element={<EpipremnumRawPictureCreateCommercialPictureComponent/>}/>
    //
    //         <Route path="/report"
    //                element={<ReportsComponent/>}/>
    //
    //         <Route path="/mercadoLibrePendingOrders"
    //                element={<MercadoLibrePendingOrdersComponent/>}/>
    //
    //         <Route path="/demo"
    //                element={<PhaseViewerComponent/>}/>
    //
    //         <Route path="/demo/drills"
    //                element={<DrillHoleViewerComponent/>}/>
    //
    //         <Route path="/demo/drills/table"
    //                element={<DrillHoleAllComponent/>}/>
    //
    //         <Route path="/demo/drills/create"
    //                element={<DrillHoleCreateCampaignComponent/>}/>
    //
    //         <Route path="/demo/large_topography"
    //                element={<TopographyViewerComponent
    //                    topographyUrl={"https://storage.googleapis.com/epipremnum-development/template/output.csv"}/>}/>
    //
    //         <Route path="/demo/small_topography"
    //                element={<TopographyViewerComponent
    //                    topographyUrl={"https://storage.googleapis.com/epipremnum-development/template/output_mini.csv"}/>}/>
    //
    //
    //         <Route path="*" element={<GenericNotFoundedComponent/>}/>
    //         <Route path="/forbidden" element={<>{Strings.Forbidden}</>}/>
    //     </Routes>
    // </Box>
}
