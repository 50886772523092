import {FC, useEffect, useState} from "react";
import {Typography, TypographyProps} from "@mui/material";
import {RescuePathStatus} from "../../../../../api/bhp/geomeasurement/StockDump/RescuePathStatus";
import {
    StockDumpRescuePathStatusEnum
} from "../../../../../api/bhp/geomeasurement/StockDump/StockDumpRescuePathStatusEnum";

interface IRescuePathStatusTypographyComponentProps {
    rescuePathStatus: StockDumpRescuePathStatusEnum,
    props?: TypographyProps
}

export const RescuePathStatusTypographyComponent: FC<IRescuePathStatusTypographyComponentProps> = (props) => {

    const [rescuePathStatusLabel, setRescuePathStatusLabel] = useState("")
    const [color, setColor] = useState("primary")

    useEffect(() => {
        setRescuePathStatusLabel(RescuePathStatus.GetLabel(props.rescuePathStatus))
        if (props.rescuePathStatus == StockDumpRescuePathStatusEnum.NotOperative) {
            setColor("error")
        }

    }, [props])

    return <Typography {...props} color={color}>{rescuePathStatusLabel}</Typography>
}